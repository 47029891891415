<template>
  <AppPage :title="$vuetify.lang.t('$vuetify.change_password')" container>
    <div class="auth-container">
      <div>
        <h2>{{ $vuetify.lang.t("$vuetify.create_new_password") }}</h2>

        <v-form ref="form">
          <v-text-field
            hide-details
            outlined
            v-model="form.old_password"
            class="rounded-lg mb-3"
            :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
            :type="show2 ? 'text' : 'password'"
            @click:append="show2 = !show2"
            :label="$vuetify.lang.t('$vuetify.old_password')"
          ></v-text-field>

          <v-text-field
            hide-details
            outlined
            v-model="form.password"
            class="rounded-lg mb-3"
            :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
            :type="show ? 'text' : 'password'"
            @click:append="show = !show"
            :label="$vuetify.lang.t('$vuetify.password')"
          ></v-text-field>

          <v-text-field
            hide-details
            outlined
            v-model="form.password_confirmation"
            class="rounded-lg mb-3"
            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
            :type="show1 ? 'text' : 'password'"
            @click:append="show1 = !show1"
            :label="$vuetify.lang.t('$vuetify.confirm_password')"
          ></v-text-field>
        </v-form>
      </div>

      <div>
        <app-button
          class="mt-3"
          @click="updatePassword"
          :disabled="isDisabled"
          :loading="loading"
          >{{ $vuetify.lang.t("$vuetify.update_password") }}</app-button
        >
      </div>
    </div>
  </AppPage>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const userModule = createNamespacedHelpers("user");

export default {
  name: "change-password",
  data() {
    return {
      form: {
        old_password: "",
        password: "",
        password_confirmation: "",
      },
      show: false,
      show1: false,
      show2: false,
    };
  },
  computed: {
    ...userModule.mapState(["loading"]),
    isDisabled() {
      const { password, password_confirmation, old_password } = this.form;
      if (!password || !password_confirmation || !old_password) return true;
      else if (password !== password_confirmation) return true;
      return false;
    },
  },
  methods: {
    ...userModule.mapActions(["UPDATE_PASSWORD"]),
    async updatePassword() {
      this.UPDATE_PASSWORD(this.form);
    },
  },
};
</script>

<style lang="scss" scoped>
.auth-container {
  min-height: calc(100vh - 56px - 24px);
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  @media only screen and (min-width: 400px) {
    padding-bottom: 3rem;
  }
}
</style>
